<template>
  <v-container fluid class="white-background agreement">
    <v-row>
      <v-col offset-md="1" md="10">
        <p class="custom-border-bottom-black width-30 font-weight-700">Site Address Acknowledgement</p>
        <div class="d-flex">
          I <v-text-field
              dense
              filled
              solo
              flat
              v-model="agreement.name"
              class="border-bottom"
              hide-details
              color="cyan"
            >
            </v-text-field>
            , Passport /FIN Number : 
            <v-text-field
              dense
              filled
              solo
              flat
              v-model="agreement.passport"
              class="border-bottom"
              hide-details
              color="cyan"
            >
            </v-text-field>,
        </div>
        <div class="d-flex">
          Company Name： <v-text-field
              dense
              filled
              solo
              flat
              v-model="agreement.company"
              class="border-bottom"
              hide-details
              color="cyan"
            >
            </v-text-field>
        </div>
        <div class="d-flex">
          Address： <v-text-field
              dense
              filled
              solo
              flat
              v-model="agreement.address"
              class="border-bottom"
              hide-details
              color="cyan"
            >
            </v-text-field>
        </div>
        <p class="pt-4 m-0">hereby acknowledge and understand that:</p>
        <p class="pt-2 m-0">
          The travelling distance and time required from Site Address to
          Working Place.
        </p>
        <p class="my-4">
          The renovation address provided is legal and able to register in
          Singapore HDB &amp; MOM. I understand that the address must be
          registered in MOM within 14 days (start from the arrival date) else I
          will bear all consequences from relevant authority.
        </p>
        <p class="my-4">
          The renovation address must meet the requirement of HDB: The maximum
          occupants in HDB, Condo or any private property must not exceed 6 pax.
          Inter Island Manpower Pte Ltd will conduct inspection and photo taking
          on the arrival date, if the renovation address does not meet the
          Singapore Government regulations, I shall appointed Inter Island
          Manpower Pte Ltd to source for legal accommodation and bear all the
          costs.
        </p>
        <p class="my-4">
          I hereby acknowledged that the information provided stated above are
          true and legit and authorized Inter Island Manpower Pte Ltd to update
          address on behalf. Inter Island Manpower Pte Ltd will report to
          employing company and Ministry of Manpower if any false information
          provided, I will bear all consequences from relevant authority, Inter
          Island Manpower Pte Ltd and employing company would not be held
          responsible for any matters arising from my accommodation issues.
        </p>
        <p class="mt-6 link d-flex" :class="{'mb-20': !agreement.signature}" v-on:click="signaturePopup()">
          Signature/ Date: 
          <v-img
            v-if="agreement.signature"
            contain
            max-height="100"
            max-width="200"
            :src="agreement.signature"
          ></v-img>
          <em v-else class="ml-6">click here to sign</em>     
        </p>
        <p class="my-4 d-flex width-30">Contact No:
          <v-text-field
            dense
            filled
            solo
            flat
            v-model="agreement.contact"
            class="border-bottom"
            hide-details
            color="cyan"
          >
          </v-text-field>
        </p>
        <p class="my-4 font-weight-700 custom-border-bottom-black width-30">Verified by Operation/Sales Person</p>
        <div class="my-4 d-flex">
          Is the accommodation sourced by Inter Island Manpower? 
          <v-btn-toggle class="ml-6 m-0" v-model="toggle_exclusive">
            <v-btn value="yes">
              Yes
            </v-btn>
            <v-btn value="no">
              No
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-layout>
          <v-flex md6>Name: {{agreement.name}}</v-flex>
          <v-flex md6 class="link d-flex" v-on:click="signaturePopup()">
            Signature / Date:
            <v-img
              v-if="agreement.signature"
              contain
              max-height="100"
              max-width="200"
              :src="agreement.signature"
            ></v-img>
            <em v-else class="ml-6">click here to sign</em>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>    
    <Dialog :commonDialog.sync="signatureDialog">
      <template v-slot:title>
        Signature
      </template>
      <template v-slot:body>
        <v-row>
          <v-col md="10" class="py-0" offset-md="1">
            <div
              id="signature-pad"
              ref="signature_div"
              class="custom-border-grey-dashed"
            >
              <canvas ref="signature"></canvas>
            </div>
          </v-col>
          <v-col md="10" class="py-0" offset-md="1">
            <p class="float-right m-0">
              <v-btn
                content="Click here to clear signature"
                v-tippy="{ arrow: true, placement: 'top' }"
                icon
                small
                class="float-right"
                v-on:click="signatureRef.clear()"
              >
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="signatureSave()"
        >
          Submit
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="signatureClose()"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>
<style type="text/css">
.agreement .v-input.v-text-field .v-input__slot {
  border: unset !important;
}

.agreement .v-input.v-text-field.border-bottom .v-input__slot {
  border-bottom: solid 1px #000000de !important;
}
</style>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";
export default {
  mixins: [CommonMixin],
  data() {
    return {
      toggle_exclusive: 'yes',
      agreement: {
        name: null,
        passport: null,
        company: null,
        address: null,
        contact: null,
        signature: null
      },
      todayDate: new Date(),
      signatureRef: null,
      signatureDialog: false,
    };
  },
  components: {
    Dialog
  },
  methods: {
    signaturePopup() {      
      this.signatureDialog = true;
      this.initSign();
    },
    initSign() {
      const _this = this;
      setTimeout(function() {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["signature"];
          let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = cparentDiv.offsetWidth;
          ccanvas.setAttribute("width", cparentWidth);
          _this.signatureRef = new SignaturePad(ccanvas);
        });
      }, 100);
    },
    signatureSave() {
      if (this.signatureRef.isEmpty()) {
        return false;
      }
      this.agreement.signature = this.signatureRef.toDataURL();
      this.signatureClose();
    },
    signatureClose() {      
      this.signatureDialog = false;
      this.signatureRef.clear();
      this.signatureRef = null;
    }
  }
};
</script>
